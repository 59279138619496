<template>
  <b-tab title="Prescriptions" :active="active" @click="loadPrescriptions">
    <b-table v-if="prescriptions.length > 0 || loadingPrescriptions" :fields="tableFields" :busy="loadingPrescriptions"
             :items="prescriptions" outlined sticky-header="500px" striped>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(description)="data">{{ data.item.description }}</template>
      <template #cell(group)="data">{{ data.item.group }}</template>
    </b-table>
    <div v-else>No prescriptions associated</div>
  </b-tab>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Prescriptions',
  props: {
    programUUID: {
      type: String,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      prescriptions: [],
      loadingPrescriptions: false,
      tableFields: [
        { key: 'description', label: 'Description' },
        { key: 'group', label: 'Group' },
      ],
    };
  },
  methods: {
    loadPrescriptions() {
      // If we already have prescriptions, don't load them again
      if (this.prescriptions.length > 0) {
        return;
      }
      this.loadingPrescriptions = true;
      axios.get(`/v1/member-search/${this.programUUID}/prescriptions`).then(response => {
        this.prescriptions = response.data;
      }).catch(err => {
        this.$noty.error('Unable to load prescriptions: ', err);
      }).finally(() => {
        this.loadingPrescriptions = false;
      });
    },
  },
  watch: {
    active() {
      if (this.active) this.loadPrescriptions();
    },
  },
};
</script>
