<script>
import axios from 'axios';
import moment from 'moment/moment';
import util from '@/scripts/tools/utils';
import copyToClipboard from '@/scripts/tools/accessibility';
import { mapGetters } from 'vuex';

export default {
  name: 'ProgramRecordsTab',
  props: {
    title: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    accountUUID: {
      type: String,
      required: true,
    },
    program: {
      type: Object,
      required: true,
    },
    clinicalFormsItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      clinicalRecordsTable: {
        fields: [
          { key: 'name', label: 'Name' },
          { key: 'daysWithSessions', label: 'Days with sessions' },
          { key: 'numberOfSessions', label: 'Number of sessions' },
          { key: 'status', label: 'Status' },
          { key: 'submittedAt', label: 'Submitted at' },
          { key: 'createdAt', label: 'Created at' },
          { key: 'link', label: 'Copy link' },
        ],
        items: [],
      },
      clinicalDataShare: {
        isLoaded: false,
        clientAllows: null,
        programAllows: null,
        error: null,
      },
      downloadFormsLoading: false,
      downloadChatLoading: false,
      downloadBillingLoading: false,
      recordsURL: '',
    };
  },
  computed: {
    ...mapGetters({
      selectedEnv: 'Core/getSelectedEnv',
    }),
    clinicalDataShareButtonVariant() {
      if (!this.clinicalDataShare.isLoaded || !this.clinicalDataShare.clientAllows) return '';
      if (!this.clinicalDataShare.programAllows) return 'danger';
      return 'success';
    },
    clinicalDataShareButtonTitle() {
      if (!this.clinicalDataShare.isLoaded) return 'Loading';
      if (!this.clinicalDataShare.clientAllows) return 'Disabled by client';
      if (!this.clinicalDataShare.programAllows) return 'Click to enable';
      return 'Click to disable'; // when enabled
    },
    clinicalDataShareButtonText() {
      if (!this.clinicalDataShare.isLoaded) return 'Loading';
      if (!this.clinicalDataShare.clientAllows || !this.clinicalDataShare.programAllows) return 'Disabled';
      return 'Enabled';
    },
  },
  methods: {
    loadData() {
      this.recordsURL = `https://swordhealth.cloud.looker.com/dashboards/2160?Patient+Sword+ID=${this.selectedEnv}-${this.program.program_id}`;
      this.clinicalRecordsTable.items = this.clinicalFormsItems;
      this.loadClinicalDataSharePreference();
    },
    loadClinicalDataSharePreference() {
      if (this.clinicalDataShare.isLoaded) return;

      axios
        .get(`v2/users/${this.accountUUID}/allow-share-clinical-data`)
        .then(({ data: sharePrefs }) => {
          this.clinicalDataShare.clientAllows = Boolean(sharePrefs.client_share_clinical_data);
          this.clinicalDataShare.programAllows = Boolean(sharePrefs.patient_share_clinical_data);
        })
        .catch(error => {
          this.clinicalDataShare.error = error.message;
          console.error('failed to get clinical data share preferences', error);
          this.$noty.error(`Failed to load clinical data share preference:<br/>${error.message}`);
        })
        .finally(() => {
          this.clinicalDataShare.isLoaded = true;
        });
    },
    toggleClinicalDataSharePreference() {
      if (!this.clinicalDataShare.isLoaded) return;

      if (!this.clinicalDataShare.clientAllows) {
        this.$noty.warning('This client does not allow clinical data to be shared.');
        return;
      }

      this.clinicalDataShare.isLoaded = false;

      axios
        .put(`v1/users/${this.accountUUID}/allow-share-clinical-data`, {
          allow: !this.clinicalDataShare.programAllows,
        })
        .then(() => {
          this.clinicalDataShare.programAllows = !this.clinicalDataShare.programAllows;
          this.$noty.success('Share preference updated.');
        })
        .catch(error => {
          console.error('failed to update data share preference', error);
          this.$noty.error('Failed to update data share preference.<br/>Try again.');
        })
        .finally(() => { this.clinicalDataShare.isLoaded = true; });
    },
    downloadClinicalForms() {
      if (this.downloadFormsLoading) return;
      this.downloadFormsLoading = true;

      const {
        program_id: programID,
        uuid: programUUID,
      } = this.program.general_information;

      axios
        .get(`/v1/member-search/${programID}/download-clinical-forms`, { responseType: 'blob' })
        .then(({ data: formZip }) => {
          util.downloadFile(formZip, `${programUUID}-forms.zip`);
        })
        .catch(error => {
          const errorMessage = `Patient has no forms submitted:<br/>${error.message}`;
          console.error('clinical forms download error', error);
          this.$noty.error(errorMessage);
        })
        .finally(() => {
          this.downloadFormsLoading = false;
        });
    },
    downloadChat() {
      if (this.downloadChatLoading) return;
      this.downloadChatLoading = true;

      axios
        .get(
          `/v1/member-search/${this.accountUUID}/${this.program.unit}/download-chat`,
          { responseType: 'blob' },
        )
        .then(response => {
          util.downloadFile(response.data, `${this.accountUUID}-${this.program.unit}-chat.txt`);
        })
        .catch(error => {
          const errorMessage = `Could not get program's messages:<br/>${error.message}`;
          console.error('chat messages download error', error);
          this.$noty.error(errorMessage);
        })
        .finally(() => {
          this.downloadChatLoading = false;
        });
    },
    async exportTransactions() {
      try {
        this.downloadBillingLoading = true;
        const { data } = await this.$store.dispatch('Financial/exportTransactions', { program_uuid: this.program.uuid });
        const buildFileName = [
          this.program.uuid,
          moment().utc().format('YYYYMMDD_HHmmss'),
          ...this.filenameSuffixes || [],
        ];
        const fileName = `${buildFileName.join('_')}.csv`;
        this.downloadFile(data, fileName);
        this.downloadBillingLoading = false;
      } catch (error) {
        this.downloadBillingLoading = false;
        let errorMsg = this.translations.global.errors.export_csv;
        if (error.response && error.response.data && error.response.data.error && error.response.data.error.detail) {
          errorMsg = `${errorMsg}: ${error.response.data.error.detail}`;
        }
        this.$noty.error(errorMsg);
      }
    },
    downloadFile(arrayBuffer, fileName) {
      const fileURL = URL.createObjectURL(new Blob([ arrayBuffer ]), { type: 'octet/stream' });
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', fileName);
      document.body.appendChild(fileLink);
      fileLink.click();
      URL.revokeObjectURL(fileLink.href);
      document.body.removeChild(fileLink);
    },
    formatDate(strDate, format = 'MMMM DD YYYY, hh:mm A') {
      const parsedDate = moment(strDate);
      return parsedDate.isValid()
        ? parsedDate.format(format)
        : '-';
    },
    copyFormLink(formURL) {
      copyToClipboard(formURL);
      this.$noty.success('Form URL copied to clipboard');
    },
  },
  watch: {
    active() {
      if (this.active) this.loadData();
    },
  },
};
</script>

<template>
  <b-tab :title="this.title" :active="active" @click="loadData">
    <b-row>
      <b-col cols="6">
        <ul class="list-unstyled">
          <li>
            <b class="mr-2">Health Information Exchanges:</b>
            <b-badge
              v-if="this.clinicalDataShare.error"
              :title="this.clinicalDataShare.error">
              Unable to show
            </b-badge>
            <b-badge
              v-else
              role="button"
              :variant="this.clinicalDataShareButtonVariant"
              :title="this.clinicalDataShareButtonTitle"
              @click="toggleClinicalDataSharePreference">
              {{ this.clinicalDataShareButtonText }}
            </b-badge>
          </li>
        </ul>
      </b-col>
      <b-col cols="6" class="text-right">
        <ul class="list-unstyled">
          <li>
            <b-badge
              role="button"
              title="Download patient's clinical forms"
              :variant="(this.downloadFormsLoading ? '' : 'success')"
              :disabled="this.downloadFormsLoading"
              @click="downloadClinicalForms">
              <b-spinner v-if="this.downloadFormsLoading" class="align-middle mr-1" small></b-spinner>
              Download clinical forms
            </b-badge>
          </li>
          <li>
            <b-badge
              role="button"
              title="Download patient's chat history"
              :variant="(this.downloadChatLoading ? '' : 'success')"
              :disabled="this.downloadChatLoading"
              @click="downloadChat">
              <b-spinner v-if="this.downloadChatLoading" class="align-middle mr-1" small></b-spinner>
              Download chat
            </b-badge>
          </li>
          <li>
            <b-badge
              role="button"
              title="Download finance records"
              :variant="(this.downloadBillingLoading ? '' : 'success')"
              :disabled="this.downloadBillingLoading"
              @click="exportTransactions">
              <b-spinner v-if="this.downloadBillingLoading" class="align-middle mr-1" small></b-spinner>
              Download Finance Records
            </b-badge>
          </li>
          <li>
            <b-badge
              role="button"
              title="View clinical records"
              variant="success"
              target="_blank"
              :href="recordsURL">
              View clinical records
            </b-badge>
          </li>
        </ul>
      </b-col>
    </b-row>
    <hr/>
    <b-row>
      <b-col>
        <b-table
          :items="clinicalRecordsTable.items"
          :fields="clinicalRecordsTable.fields"
          bordered
          striped
          show-empty>
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #empty>
            <div class="text-center my-2">
              <strong>No data to show</strong>
            </div>
          </template>
          <template #cell(daysWithSessions)="data">
            <p class="text-right">{{ (Boolean(data.value) ? data.value : '-') }}</p>
          </template>
          <template #cell(numberOfSessions)="data">
            <p class="text-right">{{ (Boolean(data.value) ? data.value : '-') }}</p>
          </template>
          <template #cell(status)="data">
            <code>{{ data.value }}</code>
          </template>
          <template #cell(submittedAt)="data">
            <p>{{ formatDate(data.value) }}</p>
          </template>
          <template #cell(createdAt)="data">
            <p>{{ formatDate(data.value) }}</p>
          </template>
          <template #cell(link)="data">
            <div class="text-center my-2">
              <b-button
                size="sm"
                variant="light"
                :disabled="!Boolean(data.value)"
                @click="() => copyFormLink(data.value)"
                :title="(Boolean(data.value) ? 'Click to copy form URL' : 'Form URL not available')">
                <b-icon :icon="(Boolean(data.value) ? 'clipboard' : 'clipboard-x')"/>
              </b-button>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-tab>
</template>
