<template>
  <div>
    <div class="create-order">
      <b-badge id="submit-patient-search" type="submit" v-if="!isAccountDeleted" :disabled="loading" @click="createManualOrder" role="button"
        variant="success">
        Create Order
      </b-badge>
      <b-badge class="ml-2" type="submit" :disabled="loading" @click="createReturnOrder" role="button"
               variant="success">
        Create Return Order
      </b-badge>
    </div>
    <OrdersList ref="orderListRef" :programUUID="this.programUUID"></OrdersList>
  </div>
</template>

<script>
import OrdersList from '@/components/MemberSearch/Orders/OrdersList.vue';

export default {
  name: 'orders',
  components: {
    OrdersList,
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    patientId: {
      type: Number,
      default: () => 0,
    },
    clientID: {
      type: Number,
      default: () => 0,
    },
    programUUID: {
      type: String,
    },
    countryStateCode: {
      type: String,
    },
    therapy: {
      type: String,
    },
    isAccountDeleted: {
      type: Boolean,
    },
  },
  methods: {
    createManualOrder() {
      this.$router.push({
        name: 'NewManualOrder',
        params: {
          patientId: this.patientId,
          clientID: this.clientID,
          countryStateCode: this.countryStateCode,
        },
      }).catch(e => e);
    },
    createReturnOrder() {
      this.$router.push({
        name: 'NewReturnOrder',
        params: {
          patientId: this.patientId,
          clientID: this.clientID,
          therapy: this.therapy,
        },
      }).catch(e => e);
    },
    loadOrders() {
      this.$refs.orderListRef.loadOrders();
    },
  },
};
</script>

<style scoped>
.create-order {
  margin: 15px 10px;
}
</style>
