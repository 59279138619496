<template>
  <div class="row">
    <div class="col-md-12">
      <b-row>
        <b-table striped bordered
        :fields="fields" :items="events" :busy="loadingEvents"
        show-empty empty-text="No Ecosystem events found!">
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(updated_at)="data">
          {{ formatDatetime(data.value) }}
        </template>
        <template #cell(status)="data">
          <b-badge :variant="statusMap[data.value] || statusMap.default">{{ data.value }}</b-badge>
        </template>
        </b-table>
      </b-row>
      <hr/>
      <b-row>
        <div v-if="!is_synchronizing">
          <b-button @click="syncEvents" variant="primary" :disabled="loading">
              <b-spinner small v-if="loading"></b-spinner>
              <span v-else>Sync Ecosystem Events</span>
          </b-button>
        </div>
        <div class="d-flex flex-row" v-if="is_synchronizing">
          <b-spinner class="mb-2" label="Loading..." variant="info" size="lg" ></b-spinner>
          <p style="margin-left: 10px; margin-top: 5px;">Synchronizing ecosystem events. Please refresh this page in few seconds</p>
        </div>
      </b-row>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import axios from 'axios';
import Vue from 'vue';

export default {
  name: 'ecosystem-events',
  data() {
    return {
      is_synchronizing: false,
      loading: false,
      events: [],
      fields: [
        { key: 'updated_at', label: 'Updated At' },
        { key: 'achievement_type', label: 'Type' },
        { key: 'status', label: 'Status', tdClass: 'addColor' },
      ],
      statusMap: {
        success: 'success',
        pending: 'warning',
        error: 'danger',
        default: 'secondary',
      },
      loadingEvents: true,
    };
  },
  props: {
    programUUID: {
      type: String,
      required: true,
    },
  },
  methods: {
    addColor(value) {
      return `${value} table-event-status`;
    },
    syncEvents() {
      this.loading = true;
      axios.post(`/v1/patients/${this.programUUID}/sync-ecosystem-events`).then(data => {
        const resp = data.data;
        if (!resp.success) {
          Vue.prototype.$noty.error(resp.message);
          return;
        }
        this.is_synchronizing = true;
        Vue.prototype.$noty.success('Successfully changed');
      }).catch(e => {
        const errorMessage = e.response?.data?.message || e.message;
        Vue.prototype.$noty.error(`Issues found while sync events: ${errorMessage}`);
      }).finally(() => {
        this.loading = false;
      });
    },
    formatDatetime(datetime) {
      try {
        return moment(datetime).tz('UTC').format('YYYY-MM-DD HH:mm:ss z');
      } catch (e) {
        return datetime;
      }
    },
    fetchEvents() {
      return axios.get(`/v1/member-search/programs/${this.programUUID}/ecosystem-events`);
    },
  },
  beforeMount() {
    this.fetchEvents().then(response => {
      this.events = response.data;
      this.events = response.data.map(e => ({
        updated_at: e.updated_at,
        achievement_type: e.achievement_type,
        status: e.status,
      }));
    }).catch(e => {
      Vue.prototype.$noty.error(`No events found: ${e.message}`);
    }).finally(() => {
      this.loadingEvents = false;
    });
  },
};
</script>
