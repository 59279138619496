<template>
  <div>
          <b-col cols="12" class="text-center" v-if="loading">
            <b-spinner type="border" small></b-spinner>
          </b-col>

          <ul class="list-unstyled" v-if="!loading">
            <li>
              <b>App installed</b>:
              <b-badge variant="success" v-if="this.appInstalled === true">Yes</b-badge>
              <b-badge variant="danger" v-else-if="this.appInstalled === false">No</b-badge>
            </li>
            <li><b>First login</b>: {{ firstLoginDate }}</li>
            <li><b>Last login</b>: {{ lastLoginDate }}</li>
            <li><b>App Version</b>: {{ this.appVersion }}</li>
          </ul>
  </div>

</template>


<script>
import axios from 'axios';
import moment from 'moment';

export default {
  name: 'MemberAppTab',
  props: {
    account_uuid: {
      type: String,
      required: true,
    },
    program: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      appInstalled: null,
      firstLogin: '',
      lastLogin: '',
      appVersion: '',
    };
  },
  computed: {
    firstLoginDate() {
      if (!this.firstLogin) {
        return '';
      }
      return moment(this.firstLogin).format('YYYY-MM-DD HH:mm:ss') || '';
    },
    lastLoginDate() {
      if (!this.lastLogin) {
        return '';
      }
      return moment(this.lastLogin).format('YYYY-MM-DD HH:mm:ss') || '';
    },
  },
  methods: {
    fetchMemberAppInfo() {
      if (!this.loading) {
        return;
      }

      axios.get(`/v1/member-search/${this.account_uuid}/programs/${this.program.general_information.uuid}/member-app`)
        .then(response => {
          this.appInstalled = response.data.app_installed;
          this.firstLogin = response.data.first_login_date;
          this.lastLogin = response.data.last_login_date;
          this.appVersion = response.data.app_version;

          this.loading = false;
        })
        .catch(() => {
          this.$noty.error('Error while fetching member app information');
        });
    },
  },
  mounted() {
    this.fetchMemberAppInfo();
  },
};

</script>
