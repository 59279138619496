<template>
  <b-row>
    <b-col cols="12" class="text-center" v-if="loading">
      <b-spinner type="border" small></b-spinner>
    </b-col>

    <b-col cols="12" v-if="!loading">
      <ul class="list-unstyled mb-0">
        <li><b>PT: </b>
          <router-link :to="{ name: 'ProfessionalEditDeprecated', params: { id: clinicalInfo.professional.id } }" target="_blank">
            {{ clinicalInfo.professional.name }} ({{ clinicalInfo.professional.id }})
          </router-link>
          <b-badge role="button" variant="info" target="_blank" class="ml-3" v-if="!isAccountDeleted"
            :href="`#/ops/member-change-owner/${programID}`">
            Manage ownership and shares
          </b-badge>
        </li>
        <li><b>Kit</b>: {{ clinicalInfo.kit }}</li>
        <li><b>Therapy</b>: {{ clinicalInfo.therapy }}</li>
        <li><b>Current protocol/program</b>: {{ clinicalInfo.protocol_prescription }}
          <b-badge role="button" variant="info" target="_blank" class="ml-3" v-if="!isAccountDeleted"
                   @click="showPrescriptionHistory">
            See history
          </b-badge>
        </li>
        <li v-if='!!clinicalInfo.phase'><b>Current phase</b>: {{ clinicalInfo.phase }}</li>
        <li><b>Current prescribed session</b>: {{ clinicalInfo.current_prescribed_session }}</li>
        <li><b>Last session</b>: {{ clinicalInfo.last_session_date }}
          <b-badge role="button" variant="info" target="_blank" class="ml-3" v-if="!isAccountDeleted"
                   @click="showResultsHistory">
            See history
          </b-badge>
        </li>
        <li v-if="isUnitDptGo"><b>Sessions blocked</b>: {{ isBlocked ? 'Yes' : 'No' }}
          <b-badge role="button" variant="success" class="ml-3" :disabled="blockSession.disabled"
            @click="sendBlockSession">
            <b-spinner v-if="blockSession.loading" label="Sending..." small></b-spinner>
            <span v-if="!blockSession.loading">{{ blockSessionLabel }}</span>
          </b-badge>
        </li>
      </ul>
      <b-modal id="bloom-prescription-history-modal" title="Prescription History" hide-footer modal-class="custom-modal">
        <b-container class="p-3">
          <b-table :items="bloomPrescriptions" :fields="bloomPrecriptionFields" v-if="bloomPrescriptions.length > 0"></b-table>
        </b-container>
      </b-modal>
      <b-modal id="sword-prescription-history-modal"
               title="Prescription History"
               hide-footer modal-class="custom-modal"
               size='xl'>
        <b-container class="p-3">
          <h5>Auto progression:
            <b-icon v-if='swordPrescriptions.auto_progression' icon="check-circle"></b-icon>
            <b-icon v-if='!swordPrescriptions.auto_progression' icon="x-circle"></b-icon>
          </h5>
          <br>
          <h5>Default Sessions</h5>
          <b-table :items="swordPrescriptions.default_sessions"
                   :fields="swordDefaultSessionFields"
                   striped hover
                   v-if="swordPrescriptions.default_sessions && swordPrescriptions.default_sessions.length > 0">
          </b-table>
          <br>
          <h5>Protocol Prescriptions</h5>
          <b-table :items="swordPrescriptions.protocol_prescriptions"
                   :fields="swordProtocolPrescriptionFields"
                   title='Protocol Prescriptions'
                   striped hover
                   v-if="swordPrescriptions.protocol_prescriptions && swordPrescriptions.protocol_prescriptions.length > 0">
          </b-table>
        </b-container>
      </b-modal>

      <b-modal id="sword-results-history-modal" title="Results History" hide-footer modal-class="custom-modal">
        <b-container class="p-3">
          <b-table :items="swordResults" :fields="swordResultFields" responsive v-if="swordResults.length > 0"></b-table>
        </b-container>
      </b-modal>

      <b-modal id="bloom-results-history-modal" title="Results History" hide-footer modal-class="custom-modal">
        <b-container class="p-3">
          <b-table :items="bloomResults" :fields="bloomResultFields" responsive v-if="bloomResults.length > 0"></b-table>
        </b-container>
      </b-modal>
    </b-col>
  </b-row>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ClinicalDetails',
  props: {
    programID: {
      type: Number,
      required: true,
    },
    programUUID: {
      type: String,
      required: true,
    },
    accountUUID: {
      type: String,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
    isAccountDeleted: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading: true,
      clinicalInfo: {
        professional: {},
        video_call_professional: {},
      },
      blockSession: {
        disabled: false,
        loading: false,
      },
      blocked: false,
      isDptGo: false,
      bloomPrescriptions: [],
      swordPrescriptions: {},
      swordDefaultSessionFields: [
        { key: 'name', label: 'Name' },
        { key: 'created_at', label: 'Created At' },
        { key: 'deleted_at', label: 'Deleted At' },
      ],
      swordProtocolPrescriptionFields: [
        { key: 'protocol', label: 'Protocol' },
        { key: 'phase', label: 'Phase' },
        { key: 'created_at', label: 'Created At' },
        { key: 'deleted_at', label: 'Deleted At' },
      ],
      bloomPrecriptionFields: [
        { key: 'prescription', label: 'Prescription' },
        { key: 'type', label: 'Type' },
        { key: 'prescription_date', label: 'Prescription Date' },
        { key: 'deleted_at', label: 'Deleted At' },
      ],
      swordResults: [],
      bloomResults: [],
      swordResultFields: [
        {
          key: 'session_number',
          label: '#',
          formatter: value => (value ? `#${value}` : '#'),
        },
        { key: 'session_name', label: 'Session Name' },
        { key: 'exercises_synced', label: 'Exercises Synced' },
        {
          key: 'pain',
          label: 'Pain',
          formatter: value => (value ? 'yes' : 'no'),
        },
        {
          key: 'fatigue',
          label: 'Fatigue',
          formatter: value => (value ? 'yes' : 'no'),
        },
        { key: 'begin', label: 'Session Begin' },
        { key: 'created_at', label: 'Created At' },
        { key: 'origin', label: 'Origin' },
      ],
      bloomResultFields: [
        {
          key: 'session_number',
          label: '#',
          formatter: value => (value ? `#${value}` : '#'),
        },
        { key: 'session_name', label: 'Session Name' },
        { key: 'exercises_synched', label: 'Exercises Synched' },
        { key: 'feedback', label: 'Feedback Synched' },
        { key: 'session_begin', label: 'Session Begin' },
        { key: 'created_at', label: 'Created At' },
        { key: 'origin', label: 'Origin' },
      ],
    };
  },
  computed: {
    videoCallPTName() {
      if (!this.clinicalInfo.video_call_professional.name) {
        return '';
      }
      return `${this.clinicalInfo.video_call_professional.name} (${this.clinicalInfo.video_call_professional.id})`;
    },
    isUnitDptGo() {
      return this.unit === 'dpt_go';
    },
    isBlocked() {
      return this.blocked;
    },
    blockSessionLabel() {
      return this.blocked ? 'Unblock session' : 'Block session';
    },
  },
  methods: {
    fetchClinicalInfo() {
      return axios.get(`/v1/member-search/programs/${this.programID}/clinical`, {
        params: {
          include: 'professionals',
          show_professional_deleted: true,
        },
      });
    },
    fetchSessionsStatus() {
      return axios.get(`v1/member-search/block-sessions/${this.accountUUID}/status/${this.programUUID}`);
    },
    fetchBloomPrescriptions() {
      return axios.get(`/v1/member-search/programs/${this.programUUID}/clinical/bloom-prescriptions`);
    },
    fetchSwordPrescriptions() {
      return axios.get(`/v1/member-search/programs/${this.programUUID}/clinical/protocol-prescriptions`);
    },
    fetchSwordResults() {
      return axios.get(`/v1/member-search/programs/${this.programID}/clinical/sword-results`);
    },
    fetchBloomResults() {
      return axios.get(`/v1/member-search/programs/${this.programUUID}/clinical/bloom-results`);
    },
    showPrescriptionHistory() {
      if (this.unit === 'sword') {
        this.fetchSwordPrescriptions().then(response => {
          this.swordPrescriptions = response.data;
          this.$bvModal.show('sword-prescription-history-modal');
        });
      }

      if (this.unit === 'bloom') {
        this.fetchBloomPrescriptions().then(response => {
          this.bloomPrescriptions = response.data.prescriptions;
          this.$bvModal.show('bloom-prescription-history-modal');
        });
      }
    },
    showResultsHistory() {
      if (this.unit === 'sword') {
        this.fetchSwordResults().then(response => {
          this.swordResults = response.data;
          this.$bvModal.show('sword-results-history-modal');
        });
      }

      if (this.unit === 'bloom') {
        this.fetchBloomResults().then(response => {
          this.bloomResults = response.data.results;
          this.$bvModal.show('bloom-results-history-modal');
        });
      }
    },
    sendBlockSession() {
      if (this.blockSession.loading) {
        return;
      }
      this.blockSession.disabled = true;
      this.blockSession.loading = true;
      axios.post('v1/member-search/block-sessions', {
        account_uuid: this.accountUUID,
        program_uuid: this.programUUID,
        blocked: !this.blocked,
      })
        .then(() => {
          this.blocked = !this.blocked;
          const message = this.blocked ? 'Block' : 'Unblock';
          this.$noty.success(`${message} successfull`, { timeout: 750, progressBar: false });
        })
        .catch(() => this.$noty.error('An unknown error ocurred, please try again'))
        .finally(() => {
          this.blockSession.disabled = false;
          this.blockSession.loading = false;
        });
    },
  },
  beforeMount() {
    this.fetchClinicalInfo().then(response => {
      this.loading = false;
      this.clinicalInfo = response.data.clinical_info;
    });
    if (this.isUnitDptGo) {
      this.fetchSessionsStatus().then(response => {
        this.loading = false;
        this.blocked = response.data.blocked;
      });
    }
  },
};
</script>

<style>
.custom-modal .modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  max-width: 90vw;
}

.custom-modal .modal-body {
  overflow-x: auto;
}

.custom-modal .modal-body table {
  width: 100%;
  min-width: 30%;
  table-layout: fixed;
  word-wrap: break-word;
}
</style>
