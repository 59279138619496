<template>
  <b-row>
    <b-col cols="12" class="text-center" v-if="loading">
      <b-spinner type="border" small></b-spinner>
    </b-col>
    <b-col cols="12">
      <ul class="list-unstyled">
        <li>
          <b>Pod version</b>: {{ pod_version }}
        </li>
      </ul>
      <ul class="list-unstyled">
        <li>
          <b>SKU</b>: {{ sku }}
        </li>
      </ul>
    </b-col>
  </b-row>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';

export default {
  name: 'BloomPodTab',
  props: {
    program_uuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      pod_version: '---',
      sku: '---',
    };
  },
  methods: {
    getActiveBloomPod() {
      axios.get(`v1/member-search/programs/${this.program_uuid}/bloom-pod`, {})
        .then(response => {
          this.pod_version = response.data.pod_version;
          this.sku = response.data.sku;
        })
        .catch(response => {
          const errMsg = `An error occurred getting active bloom pod: ${response.message}`;
          Vue.prototype.$noty.error(errMsg);
          console.error(errMsg);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  beforeMount() {
    this.getActiveBloomPod();
  },
};
</script>
