<script>
import axios from 'axios';
import * as R from 'ramda';

import AccountDeletedError from '@/scripts/errors/AccountDeletedError';
import AccountDetails from '@/views/OpsSearchProgram/partials/AccountDetails.vue';
import ProgramDetails from '@/views/OpsSearchProgram/partials/ProgramDetails.vue';
import InsuranceInfoDetails from '@/views/OpsSearchProgram/partials/InsuranceInfoDetails.vue';

const SEARCH_ACCOUNT_UUID = 'account_uuid';
const SEARCH_PROGRAM_UUID = 'program_uuid';
const SEARCH_PROGRAM_ID = 'program_id';
const SEARCH_EMAIL = 'email';
const SEARCH_PHONE = 'phone';

const isNullOrEmpty = R.either(R.isNil, R.isEmpty);

const buildPhone = R.compose(
  R.join(' '),
  R.reject(isNullOrEmpty),
  R.props([ 'country_code', 'number', 'type' ]),
);

export default {
  components: { AccountDetails, ProgramDetails, InsuranceInfoDetails },
  mounted() {
    this.automaticSearchByQuery();
  },
  data() {
    return {
      search: {
        value: '',
        type: SEARCH_ACCOUNT_UUID,
        types: [
          {
            text: 'Account UUID',
            value: SEARCH_ACCOUNT_UUID,
            validation_regex: /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
          },
          {
            text: 'Program UUID',
            value: SEARCH_PROGRAM_UUID,
            validation_regex: /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
          },
          {
            text: 'Program ID',
            value: SEARCH_PROGRAM_ID,
            validation_regex: /^[0-9]+$/,
          },
          {
            text: 'Email',
            value: SEARCH_EMAIL,
            validation_regex: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
          },
          {
            text: 'Phone Number',
            value: SEARCH_PHONE,
            validation_regex: /^[0-9]+$/,
          },
        ],
      },
      account: {},
      loading: false,
      patient: null,
      patientTransitions: null,
      programs: null,
      showPageInfo: false,
      showProgramsInfos: false,
    };
  },
  computed: {
    dynamicPlaceholder() {
      const selectedOption = this.search.types.find(
        option => option.value === this.search.type,
      );

      return selectedOption ? `Enter the ${selectedOption.text}` : 'Enter a value';
    },
  },
  methods: {
    searchMember() {
      if (!this.validateForm()) {
        return;
      }

      if (!this.$route.query || this.search.value !== this.$route.query[this.search.type]) {
        this.$router.replace({ query: { [this.search.type]: this.search.value } });
      }

      this.loading = true;
      this.showPageInfo = false;
      this.programs = null;
      this.showProgramsInfos = false;
      axios.get(
        '/v1/member-search',
        { params: { type: this.search.type, value: this.search.value, includeDeleted: true } },
      )
        .then(accountResponse => {
          const memberAccount = accountResponse.data || {};
          this.account = accountResponse.data;
          this.account.uuid = memberAccount.account_id;
          this.account.phone = buildPhone(memberAccount.phone);
          this.account.geographical_state = memberAccount.state ? memberAccount.state.toUpperCase() : '';
          this.account.country = memberAccount.country ? memberAccount.country.toUpperCase() : '';
          this.account.is_account_deleted = parseInt(this.account?.deleted_at?.seconds, 10) > 0;
          this.showPageInfo = true;
          this.$noty.success('Information loaded successfully.');
        })
        .then(() => this.loadProgramInfo())
        .catch(err => {
          if (R.path([ 'response', 'data', 'message' ], err)) {
            this.$noty.error(err.response.data.message);
          } else if (AccountDeletedError.matchesFormat(err)) {
            this.$noty.error(new AccountDeletedError().message);
          } else {
            this.$noty.error(R.propOr('Failed to load information.', 'message', err));
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    findSearchType() {
      return this.search.types.find(element => element.value === this.search.type);
    },
    validateForm() {
      if (!this.search.value) {
        this.$noty.error('You must enter a search value');
        return false;
      }

      if (!this.search.type) {
        this.$noty.error('You must select a search type');
        return false;
      }

      const searchType = this.findSearchType();

      if (!searchType) {
        this.$noty.error('You inserted an invalid search type.');
        return false;
      }

      if (searchType.validation_regex && !searchType.validation_regex.test(this.search.value)) {
        this.$noty.error('You have entered an invalid value!');
        return false;
      }

      return true;
    },
    sortProgramsByEnrolledAt(programs) {
      return programs.sort((prev, current) => (prev.enrolled_at && prev.enrolled_at > current.enrolled_at ? -1 : 1));
    },
    loadProgramInfo() {
      return axios.get(`/v1/member-search/${this.account.uuid}/programs?includeDeleted=${this.account.is_account_deleted}`)
        .then(programsResponse => {
          this.programs = this.sortProgramsByEnrolledAt(programsResponse.data);
        })
        .catch(err => {
          if (R.path([ 'response', 'data', 'message' ], err)) {
            this.$noty.error(err.response.data.message);
          } else {
            this.$noty.error(R.propOr('Failed to load programs.', 'message', err));
          }
        })
        .finally(() => {
          this.showProgramsInfos = true;
        });
    },
    automaticSearchByQuery() {
      if (!this.$route.query) {
        return;
      }

      // return the first key that is in the search types
      const searchParamsKeys = Object.keys(this.$route.query);
      const searchKey = this.search.types.map(s => s.value).find(k => searchParamsKeys.includes(k));

      if (searchKey) {
        this.search.type = searchKey;
        this.search.value = this.$route.query[searchKey];
        this.searchMember();
      }
    },
  },
};

</script>
<template>
  <b-container class="mt-4 member-search-page" fluid>
    <b-row>
      <b-col cols="4">
        <h3 v-if="showPageInfo">Member {{ account.first_name }} {{ account.last_name }}</h3>
        <h3 v-else>Member search</h3>
      </b-col>
      <b-col cols="8">
        <b-form inline class="float-right" onsubmit="return false;">
          <b-form-select :options="search.types" class="ml-3" v-model="search.type" />
          <b-form-input class="ml-3" v-model="search.value" :placeholder="dynamicPlaceholder" @keyup.enter="searchMember" />
          <b-button variant="primary" class="ml-3" @click="searchMember">
            <feather type="search"></feather>
          </b-button>
        </b-form>
      </b-col>
    </b-row>

    <b-card class="mt-3 mb-5" v-if="showPageInfo">
      <b-card-title>
        Account
        <b-badge v-if="account.is_account_deleted" variant="danger">Deleted</b-badge>
      </b-card-title>
      <AccountDetails :data="{ account }" />
    </b-card>
    <b-card class="mt-3 mb-5" title="Insurance Information" v-if="showPageInfo">
      <InsuranceInfoDetails :data="{ account }" />
    </b-card>

    <div v-if="showPageInfo">
      <h4 class="triangle-button">Programs
        <div v-show="!showProgramsInfos" class="spinner-border spinner-border-sm text-primary"
             style="width: 1.4rem; height: 1.4rem;" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </h4>
      <b-card no-body class="mt-3" v-show="showProgramsInfos">
        <div v-if="!programs || programs.length === 0" class="text-center">
          <div class="alert alert-warning"><span> No programs found! </span></div>
        </div>
        <div v-else>
          <b-tabs pills card vertical nav-wrapper-class="w-22">
            <ProgramDetails v-for="program in programs"
                            :program="program"
                            :client_id="account.client_id"
                            :geographical_state="account.geographical_state"
                            :is_account_deleted="account.is_account_deleted"
                            :account_uuid="account.uuid"
                            v-bind:key="program.uuid">
            </ProgramDetails>
          </b-tabs>
        </div>
      </b-card>
    </div>

  </b-container>
</template>

<style lang="scss" scoped>
.triangle-button {
  background-color: transparent;
  cursor: pointer;
}

</style>
