<template>
  <div>
          <b-col cols="12" class="text-center" v-if="loading">
            <b-spinner type="border" small></b-spinner>
          </b-col>

          <b-row v-if="!loading">
            <b-col cols="9">
              <ul class="list-unstyled">
                <li><b>Channel URL</b>: {{ channelURL }}</li>
                <li><b>Channel Created at</b>: {{ createdAt }}</li>
                <li>
                  <b>Is Active</b>:
                  <b-badge variant="success" v-if="isActive === true">Yes</b-badge>
                  <b-badge variant="danger" v-else>No</b-badge>
                </li>
              </ul>
            </b-col>

            <b-col cols="3" class="text-right">
              <ul class="list-unstyled">
                <li>
                  <b-badge :disabled="syncingChannel" v-if="program_is_active" role="button" variant="info" v-b-modal.modal-sync-channel>
                    <span>Sync Channel</span>
                  </b-badge>
                </li>
              </ul>
            </b-col>
          </b-row>

          <h6 class="mt-4"><strong>Channel Members:</strong></h6>
          <b-table :items="membersData.items" :fields="membersData.fields"
                 :busy="membersData.loading" bordered striped show-empty>
                 <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> Loading... </strong>
                  </div>
                </template>
        </b-table>

        <b-modal size="lg" id="modal-sync-channel" title="Sync Channel" @ok="syncChannel">
          <p>To synchronize the channel, the following validations will be performed:</p>
          <ul>
            <li>Check if the professional is on the Sendbird channel.</li>
            <li>If not, we will add it and update the channel name and image. </li>
            <li>In addition, we will remove other members who should not be on the channel.</li>
            <li>Then it will synchronize the database with the changes made.</li>
          </ul>
        </b-modal>
  </div>
</template>


<script>
import axios from 'axios';
import Vue from 'vue';
import moment from 'moment';

export default {
  name: 'ChatAppTab',
  props: {
    account_uuid: {
      type: String,
      required: true,
    },
    program_uuid: {
      type: String,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
    program_is_active: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      syncingChannel: false,
      loading: true,
      channelURL: '',
      createdAt: '',
      isActive: false,
      membersData: {
        items: [],
        fields: [
          { key: 'sendbird_id', label: 'Sendbird ID' },
          { key: 'sword_user_id', label: 'Sword User ID' },
          { key: 'sword_user_id', label: 'Sword Role' },
          { key: 'nickname', label: 'Nick Name' },
          { key: 'joined_at', label: 'Joined At' },
          { key: 'last_message_sent', label: 'Last Message Sent' },
        ],
        loading: false,
      },
    };
  },
  mounted() {
    this.fetchInfo();
  },
  methods: {
    async syncChannel() {
      try {
        this.syncingChannel = true;
        await axios.post(`/v1/member-search/programs/${this.program_uuid}/sync-channel/${this.channelURL}`,
          { member_account_uuid: this.account_uuid });
        await this.fetchInfo();
        Vue.prototype.$noty.success('Channel synced successfully');
      } catch (e) {
        const errorMessage = e?.response?.data?.message || '';
        const errorParts = errorMessage.split('[syncChannel]');
        const errorContext = errorParts.length > 1 ? errorParts[1] : '';

        Vue.prototype.$noty.error(`Failed to sync channel${errorContext}`, e);
        console.error(e);
      } finally {
        this.syncingChannel = false;
      }
    },
    async fetchInfo() {
      const getDate = ts => {
        if (!ts || parseInt(ts, 10) === 0) {
          return null;
        }
        return moment.utc(parseInt(ts, 10)).format('YYYY-MM-DD HH:mm:ss');
      };

      try {
        this.loading = true;
        const response = await axios.get('/v1/member-search/get-channel-with-members', {
          params: {
            memberAccountUuid: this.account_uuid,
            programUuid: this.program_uuid,
            unit: this.unit,
          },
        });

        this.channelURL = response.data.channel_url;
        this.createdAt = getDate(response.data?.created_at);
        this.isActive = response.data?.is_active;
        this.membersData.items = response.data?.members?.map(member => ({
          ...member,
          joined_at: getDate(member.joined_at),
          last_message_sent: getDate(member.last_message_sent),
        }));
      } catch (e) {
        Vue.prototype.$noty.error('Failed to load channel', e);
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
