<template>
  <div>
    <b-col cols="12" class="text-center" v-if="loading">
      <b-spinner type="border" small></b-spinner>
    </b-col>

    <b-row v-if="!loading">
      <b-col cols="12" lg="6">
        <ul class="list-unstyled">
          <li>
            <b>Phone Number</b>: {{ phoneNumber }}
          </li>
          <li>
            <b>Line Type</b>:
            <a :href="'https://www.twilio.com/docs/lookup/v2-api/line-type-intelligence#type-property-values'"
              target="_blank">
              {{ phoneLookup.line_type }}
            </a>
          </li>
          <li>
            <b>Is Valid Number</b>:
            <b-badge variant="success" v-if="phoneLookup.is_valid === true">Yes</b-badge>
            <b-badge variant="danger" v-else>No</b-badge>
          </li>
          <li>
            <b>Is Valid for SMS</b>:
            <b-badge variant="success" v-if="phoneLookup.can_receive_sms === true">Yes</b-badge>
            <b-badge variant="danger" v-else>No</b-badge>
          </li>
          <li>
            <b>Is Valid for Manual SMS (Inbox)</b>:
            <b-badge variant="success" v-if="phoneLookup.can_receive_manual_sms === true">Yes</b-badge>
            <b-badge variant="danger" v-else>No</b-badge>
          </li>
          <li>
            <b>Is Opt Out</b>:
            <b-badge variant="success" v-if="smsOptout.is_opt_out === true">Yes</b-badge>
            <b-badge variant="danger" v-else>No</b-badge>
          </li>
          <li v-if="smsOptout.is_opt_out === true">
            <b>Last Opted Out At</b>: {{ smsOptout.optout_at }}
          </li>
        </ul>
      </b-col>
      <b-col cols="12" lg="6">
        <ul class="list-unstyled">
          <li>
            <b>Treatment PT ID</b>:
            <router-link :to="{ name: 'ProfessionalEditDeprecated', params: { id: professionalIds.treatment_pt } }"
                         target="_blank">
              {{ professionalIds.treatment_pt }}
            </router-link>
          </li>
          <li>
            <b>Intake PT ID</b>:
            <router-link :to="{ name: 'ProfessionalEditDeprecated', params: { id: professionalIds.intake_pt } }"
                         target="_blank">
              {{ professionalIds.intake_pt }}
            </router-link>
          </li>
          <li><b>Lead Treatment PT ID</b>: {{ professionalIds.lead_treatment_pt }}</li>
          <li><b>Lead Intake PT ID</b>: {{ professionalIds.lead_intake_pt }}</li>
          <li>
            <b-badge
              role="button"
              title="Download patient's SMS history"
              :variant="(this.downloadSMSHistoryLoading ? '' : 'success')"
              :disabled="this.downloadSMSHistoryLoading"
              @click="downloadSMSHistory">
              <b-spinner v-if="this.downloadSMSHistoryLoading" class="align-middle mr-1" small></b-spinner>
              Download SMS History
            </b-badge>
          </li>
        </ul>
      </b-col>
    </b-row>

    <h6 class="mt-4"><strong>Message Exchanges (Last 30):</strong></h6>
    <b-table :items="messageExchanges.items" :fields="messageExchanges.fields" :busy="messageExchanges.loading" bordered
      striped show-empty>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong> Loading... </strong>
        </div>
      </template>
      <template #cell(sender)="row">
        <ul class="sender">
          <li><b>Role:</b> {{ row.item.sender_role }}</li>
          <li><b>User ID:</b> {{ row.item.sender_id }}</li>
          <li><b>Phone:</b> {{ row.item.sender_phone }}</li>
        </ul>
      </template>
      <template #cell(receiver)="row">
        <ul class="receiver">
          <li><b>Role:</b> {{ row.item.receiver_role }}</li>
          <li><b>User ID:</b> {{ row.item.receiver_id }}</li>
          <li><b>Phone:</b> {{ row.item.receiver_phone }}</li>
        </ul>
      </template>
      <template #cell(message)="row">
        <ul class="message">
          <li><b>Origin:</b> {{ row.item.message_origin }}</li>
          <li><b>Type:</b> {{ row.item.message_type }}</li>
          <li><b>Via:</b> {{ row.item.message_via }}</li>
        </ul>
      </template>
      <template #cell(delivery)="row">
        <ul class="delivery">
          <li><b>Sent:</b> <br /> {{ row.item.sent_at || '-' }}</li>
          <li><b>Delivered:</b> <br /> {{ row.item.delivered_at || '-' }}</li>
        </ul>
      </template>
      <template #cell(message_status)="row">
        <ul class="message_status">
          <b-badge :variant="formatStatusLabel(row.item.message_status)">
            {{ formatStatusContent(row.item.message_status) }}
          </b-badge>
          <div v-if="row.item.error.has_error === true"><b-badge variant="danger">ERROR</b-badge></div>
        </ul>
      </template>
      <template #cell(actions)="row">
        <ul class="actions">
          <b-button size="sm" :variant="row.item.error.has_error === true ? 'warning' : 'primary'"
            @click="openMessageExchangeErrorsModal(row.item.id)">VIEW DETAILS</b-button>
        </ul>
      </template>
    </b-table>

    <b-modal :id='`modal-message-exchange-errors-${program_uuid}`' size='xl'
      :title='`Message Details | Program: ${program_uuid}`'>
      <div class="card mb-4">
        <div class="card-header">
          Message Information
        </div>
        <div class="card-body">
          <b-row class="mb-3">
            <b-col>
              <div class="d-flex flex-column justify-content-center">
                <b>ID</b>
                <span> {{ selectedMessage.id }}</span>
              </div>
              <div class="d-flex flex-column justify-content-center">
                <b>Message External SID</b>
                <a v-if="selectedMessage.message_id"
                  :href="`https://console.twilio.com/us1/monitor/logs/sms/${selectedMessage.message_id}`"
                  target="_blank">
                  {{
      selectedMessage.message_id }}</a>
                <span v-else>-</span>
              </div>
              <div class="d-flex flex-column">
                <b>Message Last Status</b>
                <div class="ma-2 pa-2">
                  <b-badge :variant="formatStatusLabel(selectedMessage.message_status)">
                    <span>{{ formatStatusContent(selectedMessage.message_status) }}</span>
                  </b-badge>
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="d-flex flex-column justify-content-center">
                <b>Created</b>
                <span> {{ selectedMessage.created_at }}</span>
              </div>
              <div class="d-flex flex-column justify-content-center">
                <b>Sent</b>
                <span> {{ selectedMessage.sent_at || '-' }}</span>
              </div>
              <div class="d-flex flex-column justify-content-center">
                <b>Read</b>
                <span> {{ selectedMessage.read_at || '-' }}</span>
              </div>
              <div class="d-flex flex-column justify-content-center">
                <b>Delivered</b>
                <span> {{ selectedMessage.delivered_at || '-' }}</span>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <div v-if="selectedMessage.error.has_error === true" class="card mb-4">
        <div class="card-header">
          Error Information
        </div>
        <div class="card-body">
          <b-row class="mb-3">
            <b-col>
              <div class="d-flex flex-column justify-content-center">
                <b>Error Code</b>
                <div class="ma-2 pa-2">
                  <b-badge variant="danger" v-b-tooltip.hover title="click to view details"
                    v-if="isCodeInternal(selectedMessage.error.code)"
                    :href="`https://www.notion.so/swordhealth/TNOPS-0007-SMS-Errors`" target="_blank">
                    {{ selectedMessage.error.code }}
                    <b-icon icon="arrow-up-right"></b-icon>
                  </b-badge>
                  <b-badge variant="danger" v-b-tooltip.hover title="click to view details" v-else
                    :href="`https://www.twilio.com/docs/api/errors/${selectedMessage.error.code}`" target="_blank">
                    {{ selectedMessage.error.code }}
                    <b-icon icon="arrow-up-right"></b-icon>
                  </b-badge>
                </div>
              </div>
              <b>Error Origin</b>
              <div class="ma-2 pa-2">
                <b-badge id="error-origin" variant="info"> {{ selectedMessage.error.origin_type }}
                  <b-icon icon="exclamation-circle-fill"></b-icon>
                </b-badge>
              </div>
              <b-tooltip id="error-origin-tooltip" target="error-origin" triggers="hover" placement="top">
                <p v-show="parseErrorOrigin(selectedMessage.error.origin_type) == 'IT'">
                  It means the error was probably caused by porting or other <strong>issues with the PT phone
                    number.</strong>
                  You should contact IT to liaison with Twilio.
                </p>
                <p v-show="parseErrorOrigin(selectedMessage.error.origin_type) == 'MEMBER'">
                  It means the error was probably caused by <strong>issues with the member phone number.</strong>
                  You should contact the member or the PT.
                </p>
                <p v-show="parseErrorOrigin(selectedMessage.error.origin_type) == 'ENGINEERING'">
                  It means the error may have been caused by <strong>internal issues with our system.</strong>
                  You should contact Product to liaison with the Engineering team.
                </p>
              </b-tooltip>
            </b-col>
            <b-col>
              <div class="d-flex flex-column justify-content-center">
                <b>Error Message</b>
                <span> {{ selectedMessage.error.message }}</span>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>
  </div>

</template>


<script>
import axios from 'axios';
import Vue from 'vue';
import moment from 'moment';
import util from '@/scripts/tools/utils';

export default {
  name: 'SMSCommsTab',
  props: {
    account_uuid: {
      type: String,
      required: true,
    },
    program_uuid: {
      type: String,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
    program_is_active: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      downloadSMSHistoryLoading: false,
      phoneNumber: '',
      professionalIds: {
        treatment_pt: '',
        intake_pt: '',
        lead_treatment_pt: '',
        lead_intake_pt: '',
      },
      smsOptout: {
        is_opt_out: false,
        optout_at: '',
      },
      phoneLookup: {
        line_type: '',
        is_valid: true,
        can_receive_sms: true,
        can_receive_manual_sms: true,
        override_to_enable_all_sms: false,
        created_at: '',
        updated_at: '',
      },
      messageExchanges: {
        items: [],
        fields: [
          { key: 'id', label: '#' },
          { key: 'created_at', label: 'Created' },
          { key: 'sender', label: 'Sender' },
          { key: 'receiver', label: 'Receiver' },
          { key: 'message', label: 'Message' },
          { key: 'delivery', label: 'Delivery' },
          { key: 'message_status', label: 'Status' },
          { key: 'actions', label: 'Actions' },

        ],
      },
      messageExchangesErrors: {
        items: [],
        fields: [
          { key: 'id', label: '#' },
          { key: 'message', label: 'Message' },
          { key: 'error_code', label: 'Error Code' },
          { key: 'error_message', label: 'Error Message' },
          { key: 'origin_type', label: 'Error Origin' },
        ],
      },
      selectedMessage: {
        message_status: '',
        error: {
          origin_type: '',
        },
      },
    };
  },
  mounted() {
    this.fetchInfo();
  },
  methods: {
    openMessageExchangeErrorsModal(messageRecordId) {
      this.selectMessage(messageRecordId);
      this.$bvModal.show(`modal-message-exchange-errors-${this.program_uuid}`);
    },
    selectMessage(messageRecordId) {
      this.selectedMessage = this.messageExchanges.items.find(item => item.id === messageRecordId);
    },
    isCodeInternal(code) {
      return Number.isNaN(Number(code));
    },
    parseErrorOrigin(errorOrigin) {
      switch (errorOrigin) {
        case 'IT EXTERNAL':
          return 'IT';
        case 'MEMBER EXTERNAL':
        case 'MEMBER INTERNAL':
          return 'MEMBER';
        case 'ENGINEERING INTERNAL':
          return 'ENGINEERING';
        default:
          return 'ENGINEERING';
      }
    },
    formatStatusContent(status) {
      if (typeof status === 'string') {
        return status.toUpperCase();
      }
      return '-';
    },
    formatStatusLabel(status) {
      switch (status) {
        case 'created':
          return 'secondary';
        case 'failed':
        case 'undelivered':
        case 'canceled':
          return 'danger';
        case 'quedued':
        case 'accepted':
        case 'sending':
        case 'scheduled':
        case 'received':
        case 'receiving':
          return 'primary';
        case 'read':
        case 'delivered':
          return 'success';
        case 'sent':
          return 'info';
        default:
          return 'dark';
      }
    },
    downloadSMSHistory() {
      if (this.downloadSMSHistoryLoading) return;
      this.downloadSMSHistoryLoading = true;

      axios
        .get(
          `/v1/member-search/programs/${this.program_uuid}/communications/file`,
          {
            responseType: 'blob',
            params: {
              member_account_uuid: this.account_uuid,
            },
          },
        )
        .then(response => {
          util.downloadFile(response.data, `sms-chat-${this.program_uuid}-${this.account_uuid}.txt`);
        })
        .catch(error => {
          const errorMessage = `Could not get program's SMSs:<br/>${error.message}`;
          console.error('SMS History download error', error);
          this.$noty.error(errorMessage);
        })
        .finally(() => {
          this.downloadSMSHistoryLoading = false;
        });
    },
    async fetchInfo() {
      const getDate = ts => {
        if (!ts || parseInt(ts, 10) === 0) {
          return null;
        }
        return moment.utc(ts).format('DD-MM-YYYY HH:mm:ss');
      };

      try {
        this.loading = true;
        const response = await axios.get(`/v1/member-search/programs/${this.program_uuid}/communications/sms`, {});
        this.phoneNumber = response.data.phone_number;
        this.professionalIds = response.data.professional_ids;

        this.smsOptout = {
          ...response.data.sms_optout,
          optout_at: getDate(response.data.sms_optout.optout_at),
        };

        this.phoneLookup = {
          ...response.data.phone_lookup,
          created_at: getDate(response.data.phone_lookup.created_at),
          updated_at: getDate(response.data.phone_lookup.updated_at),
        };

        this.messageExchanges.items = response.data.message_exchanges.map(message => {
          const result = {
            ...message,
            created_at: getDate(message.created_at),
            sent_at: getDate(message.sent_at),
            delivered_at: getDate(message.delivered_at),
          };

          if (message.error.has_error) {
            result._rowVariant = 'danger';
          }

          return result;
        });

        this.messageExchangesErrors.items = response.data.message_exchanges.map(message => ({
          message: {
            ...message,
            created_at: getDate(message.created_at),
            sent_at: getDate(message.sent_at),
            read_at: getDate(message.read_at),
          },
          error: message.error,
        }));
      } catch (e) {
        Vue.prototype.$noty.error('Failed to load SMS communications data', e);
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
