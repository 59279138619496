<script>
import axios from 'axios';
import moment from 'moment/moment';

export default {
  name: 'ProgramStatesModal',
  props: {
    program: {
      type: Object,
      required: true,
    },
  },
  beforeMount() {
    this.loadData();
  },
  data() {
    return {
      previousStatesTable: {
        isLoaded: false,
        fields: [
          { key: 'created_at', label: 'Transition Date' },
          { key: 'current_state', label: 'From State' },
          { key: 'new_state', label: 'To State' },
          { key: 'reason', label: 'Reason' },
          { key: 'explanation', label: 'Explanation' },
          { key: 'attachments_url', label: 'Attachments' },
        ],
        items: [],
      },
      stateChangeDropdown: {
        isLoaded: false,
        selectedValue: null,
        selected: null,
        options: [
          { value: null, text: 'Loading options', disabled: true },
        ],
      },
      stateTransitionModal: {
        id: `state-transition-modal-${this.program.general_information.uuid}`,
        isVisible: false,
        isLoading: false,
      },
    };
  },
  computed: {
    isToDisableStateChangeConfirm() {
      return (
        this.stateChangeDropdown.selectedValue === null
        || this.stateTransitionModal.isLoading
      );
    },
  },
  methods: {
    loadData() {
      if (this.previousStatesTable.isLoaded) return;

      const programId = this.program.general_information.program_id;
      axios
        .get(`/v1/patients/${programId}/state`, { params: { include_history: true } })
        .then(({ data }) => {
          this.previousStatesTable.items = data.current_state.transition_history;
          this.stateChangeDropdown.options = this.mapStateTransitionDropdownOptions(data.possible_transitions);
          this.stateChangeDropdown.isLoaded = true;
        })
        .catch(error => {
          console.error('failed to load state data: ', error);
          this.$noty.error(`Failed to load program's state history:<br/>${error.message}`);
        })
        .finally(() => {
          this.previousStatesTable.isLoaded = true;
        });
    },
    confirmedStateChange() {
      this.stateTransitionModal.isLoading = true;

      const patientId = this.program.general_information.program_id;
      const payload = {
        patientId,
        patientUUID: this.program.general_information.uuid,
        state: this.stateChangeDropdown.selectedValue,
        reason: 'manual.admin.ops',
        force_transition: true,
      };

      axios
        .post(`/v1/patients/${patientId}/state`, payload)
        .then(({ data }) => {
          this.hideStateTransitionModal();
          this.previousStatesTable.items = [ ...this.previousStatesTable.items, data.current_state.last_transition ];
          this.stateChangeDropdown.options = this.mapStateTransitionDropdownOptions(data.possible_transitions);
          this.$noty.success('State updated');
        })
        .catch(error => {
          console.error('error trying to update program\'s state', error);
          this.$noty.error(`Error trying to force-update program's state: ${error.message}`);
          this.stateTransitionModal.isLoading = false;
        });
    },
    mapStateTransitionDropdownOptions(possibleTransitions) {
      if (possibleTransitions === null) {
        return [ { value: null, text: 'No transition available', disabled: true } ];
      }

      return [
        { value: null, text: 'Select an option', disabled: true },
        ...possibleTransitions,
      ];
    },
    hideModal() {
      this.$emit('hideModal');
    },
    showStateTransitionModal() {
      this.stateTransitionModal.isVisible = true;
    },
    hideStateTransitionModal() {
      this.stateTransitionModal.isVisible = false;
      this.stateTransitionModal.isLoading = false;
      setTimeout(() => {
        this.stateChangeDropdown.selectedValue = null;
      }, 300);
    },
    formatDate(strDate, format = 'MMMM DD YYYY, hh:mm A Z') {
      const parsedDate = moment(strDate);
      return parsedDate.isValid()
        ? parsedDate.format(format)
        : '-';
    },
  },
};
</script>

<template>
  <b-modal :id="`modal-state-machine-${this.program.program_id}`" @hide="hideModal" size='xl' title='State machine'>
    <b-row>
      <b-col>
        <b-table
          :busy="!previousStatesTable.isLoaded"
          :items="previousStatesTable.items"
          :fields="previousStatesTable.fields"
          bordered
          striped
          show-empty>
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #empty>
            <div class="text-center my-2">
              <strong>No data to show</strong>
            </div>
          </template>
          <template #cell(created_at)="data">
            <p>{{ formatDate(data.value) }}</p>
          </template>
          <template #cell(current_state)="data">
            <code>{{ data.value }}</code>
          </template>
          <template #cell(new_state)="data">
            <code>{{ data.value }}</code>
          </template>
          <template #cell(attachments_url)="data">
            <a v-if="data.value[0]" :href="data.value[0]" target="_blank">
              View file
            </a>
            <span v-else>&#8210;</span>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
          <b-button
          class="mb-3"
          variant="primary"
          :disabled="!stateChangeDropdown.isLoaded"
          @click="showStateTransitionModal">
          Force state transition
        </b-button>
      </b-col>
    </b-row>

    <b-modal
      title="Force state transition"
      v-model="stateTransitionModal.isVisible"
      @hide="hideStateTransitionModal"
      centered>
      <b-form-group
        label="Change program's state to"
        label-for="state-change-dropdown">
        <b-form-select
          id="state-change-dropdown"
          class="mb-2 mr-sm-2 mb-sm-0"
          v-model="stateChangeDropdown.selectedValue"
          :options="stateChangeDropdown.options"
          :disabled="!stateChangeDropdown.isLoaded">
        </b-form-select>
      </b-form-group>
      <template #modal-footer>
        <b-button
          :disabled="stateTransitionModal.isLoading"
          @click="hideStateTransitionModal">
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="isToDisableStateChangeConfirm"
          @click="confirmedStateChange">
          Confirm
        </b-button>
      </template>
    </b-modal>

  </b-modal>
</template>
