<template>
  <b-row>
    <b-col cols="12" class="text-center" v-if="loading">
      <b-spinner type="border" small></b-spinner>
    </b-col>

    <b-col cols="6" v-if="!loading">
      <ul class="list-unstyled">
        <li><b>System SN</b>: {{ systemSerialNumber }} </li>
        <li><b>Tablet SN</b>: {{ tabletSerialNumber }} </li>
        <li><b>First login</b>: {{ firstLoginDate }} </li>
        <li><b>Last login</b>: {{ lastLoginDate }} </li>
        <li><b>App Version</b>: {{ currentApkVersion }}
        </li>
      </ul>
    </b-col>
    <b-col cols="6" v-if="!loading">
      <ul class="list-unstyled">
        <li><b-badge role="button" variant="success" @click="openModalDTVersions">Details on latest app versions</b-badge></li>
        <li v-if="tabletSerialNumber">
          <b-badge role="button" variant="warning" @click="forceDeviceUpdate(tabletSerialNumber)">Force update</b-badge>
        </li>
        <li v-if="showTrackersButton"><b-badge role="button" variant="success" @click="openModalTrackers">Details on trackers</b-badge></li>
        <li v-if="showBigQueryButtons"><b-badge role="button" variant="warning" @click="openModalBreadcrumbs">View Breadcrumbs</b-badge></li>
        <li v-if="showBigQueryButtons"><b-badge role="button" variant="warning" @click="openModalTabletBatteryAnalysis">
          View Tablet battery analysis
        </b-badge></li>
        <li v-if="showBigQueryButtons"><b-badge role="button" variant="warning" @click="openModalMotionTrackersBatteryAnalysis">
          View Motion trackers battery analysis</b-badge></li>
        <li v-if="showBigQueryButtons"><b-badge role="button" variant="warning" @click="openModalExerciseMetrics">View Exercise metrics</b-badge></li>
      </ul>

      <!-- Trackers Modal -->
      <b-modal :id='`modal-trackers-${program.general_information.uuid}`' title="Trackers" centered size='xl'>
        <b-table :fields="trackers.fields" :items="trackers.items" bordered striped show-empty></b-table>
      </b-modal>

      <!-- APK Releases Modal -->
      <b-modal :id='`modal-apk-releases-${program.general_information.uuid}`' title="Latest App versions avaliable" centered size='xl'>
        <b-table :items='apkReleases.items' :busy="apkReleases.loading" bordered striped show-empty>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong> Loading... </strong>
            </div>
          </template>
        </b-table>
      </b-modal>

      <!-- Breadcrumbs Modal -->
      <b-modal :id='`modal-breadcrumbs-${program.general_information.uuid}`' title="Breadcrumbs" centered size='xl'>
        <b-container class="modal-body overflow-auto" fluid>
          <b-row>
            <b-col class="px-0">
              <b-table :items="breadcrumbs.items" :fields="breadcrumbs.fields" :busy="breadcrumbs.loading" bordered striped show-empty>
                <template #cell(date)="{ value: date }">
                  <p class="text-nowrap mb-0">{{ date }}</p>
                </template>

                <template #cell(value)="{ value }">
                  <p class="text-break mb-0">{{ value }}</p>
                </template>

                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> Loading... </strong>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-container>
      </b-modal>

      <!-- Tablet Battery analisys Modal -->
      <b-modal :id='`modal-tablet-battery-analysis-${program.general_information.uuid}`'
               title="Tablet battery analysis" centered size='xl'>
        <b-table :items="tabletBatteryAnalysis.items" :fields="tabletBatteryAnalysis.fields"
                 :busy="tabletBatteryAnalysis.loading" bordered striped show-empty>
                 <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> Loading... </strong>
                  </div>
                </template>
        </b-table>
      </b-modal>

      <!-- Motion trackers battery analisys Modal -->
      <b-modal :id='`modal-motion-trackers-battery-analysis-${program.general_information.uuid}`'
               title="Motion trackers battery analysis" centered size='xl'>
        <b-table :items="motionTrackersBatteryAnalysis.items" :fields="motionTrackersBatteryAnalysis.fields"
                 :busy="motionTrackersBatteryAnalysis.loading" bordered striped show-empty>
                 <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> Loading... </strong>
                  </div>
                </template>
        </b-table>
      </b-modal>

      <!-- Exercise metrics Modal -->
      <b-modal :id='`modal-exercise-metrics-${program.general_information.uuid}`'
               title="Exercise metrics" centered size='xl'>
        <b-table :items="exerciseMetrics.items" :fields="exerciseMetrics.fields"
                 :busy="exerciseMetrics.loading" bordered striped show-empty>
                 <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> Loading... </strong>
                  </div>
                </template>
        </b-table>
      </b-modal>
    </b-col>
  </b-row>
</template>
<script>

import axios from 'axios';
import moment from 'moment';

export default {
  name: 'DTTab',
  props: {
    account_uuid: {
      type: String,
      required: true,
    },
    program: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      devices: [],
      loginDates: {},
      trackers: {
        fields: [
          { key: 'serial_number', label: 'serial_number' },
          { key: 'firmware_version', label: 'firmware_version' },
        ],
        items: [],
      },
      modal: {
        isVisible: false,
        isLoading: false,
      },
      apkReleases: {
        fetched: false,
        loading: true,
        items: [],
      },
      breadcrumbs: {
        fetched: false,
        loading: true,
        fields: [
          { key: 'date', label: 'Date' },
          { key: 'value', label: 'Value' },
          { key: 'tab_model', label: 'Tab Model' },
          { key: 'os_version', label: 'OS Version' },
          { key: 'app_version', label: 'APP Version' },
          { key: 'patient_id', label: 'Patient ID' },
          { key: 'device_serial_number', label: 'Device SN' },
        ],
        items: [],
      },
      exerciseMetrics: {
        fetched: false,
        loading: true,
        fields: [
          { key: 'session_time', label: 'Session Date' },
          { key: 'exercise_name', label: 'Exercise Name' },
          { key: 'exercise_position', label: 'Exercise Position' },
          { key: 'session_order', label: 'Session Order' },
          { key: 'quality_score', label: 'Quality Score' },
          { key: 'initial_pos_problems_level', label: 'Initial Pos Problems Level' },
          { key: 'metrics_problems_level', label: 'Metrics Problems Level' },
          { key: 'skipped_exercise_sys_prob', label: 'Skipped Exercise (sys prob)' },
          { key: 'wrong_movements', label: 'Wrong Movements' },
        ],
        items: [],
      },
      tabletBatteryAnalysis: {
        fetched: false,
        loading: true,
        fields: [
          { key: 'date_time', label: 'Date' },
          { key: 'battery', label: 'Battery percentage (%)' },
        ],
        items: [],
      },
      motionTrackersBatteryAnalysis: {
        fetched: false,
        loading: true,
        fields: [
          { key: 'date_time', label: 'Date' },
        ],
        items: [],
      },
    };
  },
  computed: {
    systemSerialNumber() {
      return this.devices[0]?.system?.serial || null;
    },
    tabletSerialNumber() {
      return this.devices[0]?.serial_number || null;
    },
    currentApkVersion() {
      return this.devices[0]?.current_apk_version || null;
    },
    showTrackersButton() {
      return this.devices[0]?.system?.trackers?.length > 0;
    },
    showBigQueryButtons() {
      return Boolean(this.devices[0]?.serial_number);
    },
    firstLoginDate() {
      if (!this.loginDates.first_login_date) {
        return '';
      }
      return moment(this.loginDates.first_login_date).format('YYYY-MM-DD HH:mm:ss') || '';
    },
    lastLoginDate() {
      if (!this.loginDates.last_login_date) {
        return '';
      }
      return moment(this.loginDates.last_login_date).format('YYYY-MM-DD HH:mm:ss') || '';
    },
  },
  methods: {
    timestampToDateTime(timestamp) {
      return moment(timestamp).format('YYYY-MM-DD HH:mm:ss');
    },
    buildTrackers() {
      this.trackers.items = this.devices[0]?.system.trackers.map(t => ({
        serial_number: t.tracker?.serial_number,
        firmware_version: t.tracker_info?.firmware || null,
      }));
    },
    fetchReleases() {
      if (this.apkReleases.fetched) {
        return;
      }
      this.apkReleases.loading = true;
      axios.get('/v1/releases/apk', { params: { limit: 5 } })
        .then(({ data }) => {
          this.apkReleases.items = data.releases.map(r => ({
            release_id: r.release_id,
            version: r.version,
            created_at: moment(r.created_at).format('YYYY-MM-DD HH:mm:ss'),
            updated_at: moment(r.updated_at).format('YYYY-MM-DD HH:mm:ss'),
          }));
          this.apkReleases.fetched = true;
        }).finally(() => {
          this.apkReleases.loading = false;
        });
    },
    fetchTabletBatteryAnalysis() {
      if (this.tabletBatteryAnalysis.fetched) {
        return;
      }
      this.tabletBatteryAnalysis.loading = true;
      axios.get(`/v1/member-search/programs/${this.tabletSerialNumber}/battery-info?interval=60`)
        .then(response => {
          this.tabletBatteryAnalysis.items = response.data?.sort((a, b) => this.sortByKey(a, b, 'date_time')) || [];
          this.tabletBatteryAnalysis.fetched = true;
        })
        .catch(() => {
          this.$noty.error('Error while fetching tablet battery analysis');
        })
        .finally(() => {
          this.tabletBatteryAnalysis.loading = false;
        });
    },
    fetchTrackersBatteryAnalysis() {
      if (this.motionTrackersBatteryAnalysis.fetched) {
        return;
      }
      this.motionTrackersBatteryAnalysis.loading = true;
      axios.get(`/v1/member-search/programs/${this.tabletSerialNumber}/mts?interval=60`)
        .then(response => {
          const reducedResponse = this.buildTrackersItems(response.data);
          this.motionTrackersBatteryAnalysis.items = reducedResponse.sort((a, b) => this.sortByKey(a, b, 'date_time'));
          this.motionTrackersBatteryAnalysis.fields = this.buildTrackersFields(reducedResponse);
          this.motionTrackersBatteryAnalysis.fetched = true;
        })
        .catch(() => {
          this.$noty.error('Error while fetching tablet battery analysis');
        })
        .finally(() => {
          this.motionTrackersBatteryAnalysis.loading = false;
        });
    },
    fetchExerciseMetrics() {
      if (this.exerciseMetrics.fetched) {
        return;
      }
      this.exerciseMetrics.loading = true;
      axios.get(`/v1/member-search/programs/${this.program.general_information.program_id}/exercise-metrics?interval=60`)
        .then(response => {
          this.exerciseMetrics.items = response.data?.sort((a, b) => this.sortByKey(a, b, 'session_time'));
          this.exerciseMetrics.fetched = true;
        })
        .catch(() => {
          this.$noty.error('Error while fetching tablet battery analysis');
        })
        .finally(() => {
          this.exerciseMetrics.loading = false;
        });
    },
    fetchBreadcrumbs() {
      if (this.breadcrumbs.fetched) {
        return;
      }
      axios.get(`/v1/member-search/programs/${this.program.general_information.program_id}/breadcrumbs?interval=30`)
        .then(response => {
          this.breadcrumbs.items = response.data.map(breadcrumbsResponse => ({
            date: this.timestampToDateTime(breadcrumbsResponse.timestamp_utc),
            value: breadcrumbsResponse.value,
            tab_model: breadcrumbsResponse.tab_model,
            os_version: breadcrumbsResponse.os_version,
            app_version: breadcrumbsResponse.app_version,
            patient_id: breadcrumbsResponse.patient_id,
            device_serial_number: breadcrumbsResponse.device_serial_number,
          }));
        })
        .catch(() => {
          this.$noty.error('Error while fetching breadcrumbs');
        })
        .finally(() => {
          this.breadcrumbs.loading = false;
        });
    },
    async forceDeviceUpdate(deviceSerialNumber) {
      const confirm = await this.$bvModal.msgBoxConfirm('Are you sure to force update?');
      if (!confirm) return;

      const selectedEnv = this.$store.getters['Core/getSelectedEnv'];
      try {
        await this.$store.dispatch('CSM/createForceUpdate', { deviceSerialNumber, selectedEnv });
        this.$noty.success('Device version updated with success');
      } catch (err) {
        if (err.response && err.response.status === 409) {
          this.$noty.warning('Device is already updated in the last version');
          return;
        }
        this.$noty.error('An error occurred while creates a force update');
        console.error(err);
      }
    },
    fetchDTInfo() {
      axios.get(
        `/v1/member-search/${this.account_uuid}/programs/${this.program.general_information.program_id}/dt`,
      )
        .then(response => {
          const dtInfo = response.data;
          this.devices = dtInfo.program_devices;
          this.loginDates = dtInfo.login_dates;
          this.serialNumber = dtInfo.program_devices[0]?.serial_number || null;
          this.buildTrackers();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openModalDTVersions() {
      this.$bvModal.show(`modal-apk-releases-${this.program.general_information.uuid}`);
      this.fetchReleases();
    },
    openModalTrackers() {
      this.$bvModal.show(`modal-trackers-${this.program.general_information.uuid}`);
    },
    openModalTabletBatteryAnalysis() {
      this.$bvModal.show(`modal-tablet-battery-analysis-${this.program.general_information.uuid}`);
      this.fetchTabletBatteryAnalysis();
    },
    openModalMotionTrackersBatteryAnalysis() {
      this.$bvModal.show(`modal-motion-trackers-battery-analysis-${this.program.general_information.uuid}`);
      this.fetchTrackersBatteryAnalysis();
    },
    openModalExerciseMetrics() {
      this.$bvModal.show(`modal-exercise-metrics-${this.program.general_information.uuid}`);
      this.fetchExerciseMetrics();
    },
    openModalBreadcrumbs() {
      this.$bvModal.show(`modal-breadcrumbs-${this.program.general_information.uuid}`);
      this.fetchBreadcrumbs();
    },
    hideModal() {
      this.modal.isVisible = false;
      this.modal.isLoading = false;
    },
    sortByKey(a, b, key) {
      if (a[key] < b[key]) {
        return 1;
      }
      if (a[key] > b[key]) {
        return -1;
      }
      return 0;
    },
    buildTrackersItems(data) {
      return data.reduce((acc, tracker) => {
        const exists = acc.find(r => r.date_time === tracker.date_time);
        if (exists) {
          exists[tracker.tracker_serial_number] = tracker.battery;
        } else {
          acc.push({ date_time: tracker.date_time, [tracker.tracker_serial_number]: tracker.battery });
        }
        return acc;
      }, []);
    },
    buildTrackersFields(data) {
      return data.reduce((acc, tracker) => {
        const exists = acc.find(a => a.key === tracker.tracker_serial_number);
        if (!exists) {
          acc.push({ key: tracker.tracker_serial_number, label: tracker.tracker_serial_number });
        }
        return acc;
      }, []);
    },
  },
  mounted() {
    this.fetchDTInfo();
  },
};
</script>
