<script>
import axios from 'axios';
import * as R from 'ramda';
import { mapGetters } from 'vuex';


import { definitions } from '@sword-health/ui-core';
import moment from 'moment/moment';

const countryOptions = definitions.swordCountriesList.map(country => ({
  text: country.name,
  value: country.code.toUpperCase(),
}));

const isNullOrEmpty = R.either(R.isNil, R.isEmpty);

const buildAddress = R.compose(
  R.join(', '),
  R.reject(isNullOrEmpty),
  R.props([ 'address', 'zip_code', 'city', 'geographical_state', 'country' ]),
);

const parsePhone = R.compose(
  R.zipObj([ 'country_code', 'number', 'type' ]),
  R.take(3),
  R.split(' '),
  R.when(isNullOrEmpty, R.always('')),
  R.trim,
);

export default {
  name: 'ProgramDetails',
  props: {
    data: {
      type: Object,
      default: () => ({
        account: {},
      }),
    },
  },
  data() {
    return {
      countryOptions,
      passwordReset: {
        disabled: false,
        loading: false,
      },
      editing: false,
      saving: false,
      userAddress: buildAddress(this.data.account),
      patient: { ...this.data.account },
      impersonationInProgress: false,
    };
  },
  computed: {
    ...mapGetters({
      environmentConfigs: 'Core/getEnvironmentConfigs',
    }),
  },
  methods: {
    timestampToDateTime(timestamp) {
      return moment(timestamp * 1000).format('YYYY-MM-DD HH:mm:ss');
    },
    onCountryChange() {
      this.patient.geographical_state = '';
    },
    sendPasswordResetEmail() {
      if (this.passwordReset.loading) {
        return;
      }
      this.passwordReset.disabled = true;
      this.passwordReset.loading = true;
      axios.post('v1/onboarding/members/password-recover', { account_id: this.data.account.uuid })
        .then(() => this.$noty.success('Password recover email sent', { timeout: 750, progressBar: false }))
        .catch(() => this.$noty.error('An unknown error ocurred, please try again'))
        .finally(() => {
          this.passwordReset.disabled = false;
          this.passwordReset.loading = false;
        });
    },
    save() {
      this.saving = true;
      axios.put(`v1/member-search/${this.data.account.uuid}`, this.buildPayload())
        .then(() => {
          this.$noty.success('account updated successfully');
          this.saving = false;
          this.data.account = { ...this.patient };
          this.userAddress = buildAddress(this.patient);
        }).catch(e => {
          this.patient = { ...this.data.account };
          let msg = 'Failed to update account.';
          if (e.response.data.error_description) {
            msg += ` (${JSON.stringify(e.response.data.error_description)})`;
          }
          this.$noty.error(msg);
        });
    },
    buildPayload() {
      return {
        first_name: this.data.account.first_name,
        last_name: this.data.account.last_name,
        phone: parsePhone(this.patient.phone),
        preferred_language: this.data.account.preferred_language,
        gender: this.data.account.gender,
        zip_code: this.patient.zip_code,
        city: this.patient.city,
        timezone: this.data.account.timezone,
        preferred_name: this.data.account.first_name,
        country: this.patient.country,
        address: this.patient.address,
      };
    },
    edit() {
      this.editing = true;
    },
    cancel() {
      this.editing = false;
      this.patient = { ...this.data.account };
    },
    async impersonate() {
      try {
        this.impersonationInProgress = true;
        if (!this.data.account.uuid) {
          this.$noty.error('Member must create an account first');
          return;
        }

        const configs = this.environmentConfigs ?? {};

        const onboardingURL = configs.onboarding_url.replace(/\/$/, '');

        if (!onboardingURL) {
          this.$noty.error('Unable to find a valid onboarding URL');
          return;
        }

        const { data } = await axios.post('v1/onboarding/members/impersonation/generate', { account_id: this.data.account.uuid });
        window.open(`${onboardingURL}/impersonate/${data.token}`, '_blank');
      } catch (err) {
        console.error('Failed to impersonate: ', err);
        this.$noty.error('An unknown error ocurred, please try again');
      } finally {
        this.impersonationInProgress = false;
      }
    },
  },
};
</script>
<template>
  <b-row>
    <b-col cols="6">
      <ul class="list-unstyled">
        <li><b>Account UUID</b>: {{ data.account.uuid }}</li>
        <li><b>First name</b>: {{ patient.first_name }}</li>
        <li><b>Last name</b>: {{ patient.last_name }}</li>
        <li><b>Email</b>: {{ patient.email }}</li>
        <li><b>Phone</b>: {{ patient.phone }}</li>
        <li><b>Address</b>: {{ userAddress }}</li>
        <li><b>CC ID</b>: {{ data.account.care_coordinator.id }}</li>
        <li><b>Created at</b>: {{ timestampToDateTime(data.account.created_at.seconds) }} UTC</li>
      </ul>
    </b-col>
    <b-col cols="6" class="text-right">
      <ul class="list-unstyled" v-if="!patient.is_account_deleted">
        <li>
          <b-badge role="button" variant="info" v-b-modal.modal-edit-account @click="edit">Edit member data</b-badge>
        </li>
        <li>
          <b-badge role="button" variant="success" :disabled="passwordReset.disabled" @click="sendPasswordResetEmail">
            <b-spinner v-if="passwordReset.loading" label="Sending..." variant="success" small></b-spinner>
            <span v-if="!passwordReset.loading">Send password reset email</span>
          </b-badge>
        </li>
        <li>
          <b-overlay :show="impersonationInProgress" spinner-small>
            <b-badge role="button" variant="primary" @click="impersonate">
              Enroll for member
            </b-badge>
          </b-overlay>
        </li>
      </ul>
    </b-col>
    <b-modal id="modal-edit-account" title="Edit account" @ok="save" @cancel="cancel">
      <b-form-group :disabled="true" label="Email address:">
        <b-form-input v-model="patient.email" type="email"/>
      </b-form-group>
      <b-form-group label="Phone number:">
        <b-form-input v-model="patient.phone"/>
      </b-form-group>
      <b-form-group label="Address:">
        <b-form-input v-model="patient.address"
                      :state="patient.address !== data.account.address
                      ? patient.address.length <= 35
                      : null"/>
      </b-form-group>
      <b-form-group label="Zip code:">
        <b-form-input v-model="patient.zip_code"/>
      </b-form-group>
      <b-form-group label="Country:">
        <b-form-select @change="onCountryChange" v-model="patient.country" :options="countryOptions"/>
      </b-form-group>
      <b-form-group label="City:">
        <b-form-input v-model="patient.city"/>
      </b-form-group>
    </b-modal>
  </b-row>
</template>
