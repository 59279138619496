<template>
  <div class="row">
    <div class="col-md-12">
      <div class="text-center my-2" v-if="loadingSectionRequired || loadingSectionTable">
        <b-spinner class="align-middle"></b-spinner>
      </div>
    </div>
    <div class="col-md-12" v-if="!loadingSectionRequired && !loadingSectionTable">
      <ul class="list-unstyled">
        <li>
          <b>Referral is required</b>:
          <b-badge variant="success" v-if="isRequired === true">Yes</b-badge>
          <b-badge variant="danger" v-else>No</b-badge>
        </li>
      </ul>
      <b-col cols="12">
        <table class="table">
          <thead class="thead-light">
            <tr class="row">
              <th scope="col" class="col-md-1"><b>Provider</b></th>
              <th scope="col" class="col-md-2"><b>Therapist</b></th>
              <th scope="col" class="col-md-3"><b>External ID</b></th>
              <th scope="col" class="col-md-2"><b>Status</b></th>
              <th scope="col" class="col-md-2"><b>Status Detail</b></th>
              <th scope="col" class="col-md-2"><b>Updated At</b></th>
            </tr>
          </thead>
          <tbody>
            <tr class="row" v-for="referral in referrals" :key="referral.id">
              <td class="col-md-1">{{ referral.provider }}</td>
              <td class="col-md-2">{{ getUserEmail(referral.therapist_id) }}</td>
              <td class="col-md-3">{{ getExternalID(referral) }}</td>
              <td class="col-md-2">
                <b-badge :variant="getBadge(referral.status)">{{ referral.status }}</b-badge>
              </td>
              <td class="col-md-2">{{ referral.status_detail }}</td>
              <td class="col-md-2">{{ formatDatetime(referral.updated_at) }}</td>
            </tr>
          </tbody>
        </table>
        <div class="col-12 d-flex justify-content-center mt-2">
          <b-pagination v-if="showPagination()"
                        @change="changePage"
                        v-model="currentPage"
                        :total-rows="total"
                        :per-page="itemsPerPage"
                        size="md">
          </b-pagination>
        </div>
      </b-col>
      <div class="col-md-4">
        <div class="row" v-if="this.referrals.length">
          <div class="col-md-6">
            <b-form-select v-model="referralType" :options="dropdownOptions"></b-form-select>
          </div>
          <div class="col-md-6">
            <b-button @click="requestReferral" variant="primary" :disabled="loading">
              <b-spinner small v-if="loading"></b-spinner>
              <span v-else>Request Referral</span>
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import axios from 'axios';
import moment from 'moment-timezone';
import Vue from 'vue';

export default {
  name: 'ReferralsDetails',
  props: {
    referralType: {
      type: String,
      default: 'WHEEL',
    },
    programUUID: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      users: {},
      loading: false,
      loadingSectionTable: true,
      loadingSectionRequired: true,
      itemsPerPage: 10,
      currentPage: 1,
      total: 0,
      isRequired: false,
      referrals: {},
      dropdownOptions: [
        {
          value: 'OPENLOOP',
          text: 'Openloop',
        },
        {
          value: 'WHEEL',
          text: 'Wheel',
        },
      ],
    };
  },
  methods: {
    requestUserInfo(userId) {
      axios.get(`v1/users/${userId}`).then(({ data }) => {
        Vue.set(this.users, userId, data.user);
      }).catch(err => {
        console.error(err);
      });
    },
    fecthIsRequired() {
      axios.get(
        `/v1/onboarding/referrals/${this.programUUID}/required`,
      ).then(response => {
        this.isRequired = response.data.payload;
        this.loadingSectionRequired = false;
      });
    },
    fetchReferrals() {
      this.loadingSectionTable = true;
      const page = this.currentPage - 1;
      axios.get(
        `/v1/onboarding/referrals/${this.programUUID}`,
        {
          params: {
            offset: page * this.itemsPerPage,
            limit: this.itemsPerPage,
          },
        },
      ).then(response => {
        const therapistIds = [];
        response.data.referrals.forEach(referral => {
          if (!therapistIds.includes(referral.therapist_id)) {
            this.requestUserInfo(referral.therapist_id);
          }
          therapistIds.push(referral.therapist_id);
        });
        this.referrals = response.data.referrals;
        this.total = response.data.pagination.total_rows;
        this.loadingSectionTable = false;
      });
    },
    requestReferral() {
      this.loading = true;
      axios.post(`/v1/onboarding/referrals/${this.programUUID}`, { provider: this.referralType }).then(() => {
        Vue.prototype.$noty.success('Referral successfully requested');
      }).catch(e => {
        Vue.prototype.$noty.error(`Issues found while request referral ${e.message}`);
      }).finally(() => {
        this.loading = false;
        this.fetchReferrals();
      });
    },
    getBadge(status) {
      switch (status) {
        case 'Failed':
        case 'Rejected':
          return 'danger';
        case 'Accepted':
          return 'success';
        case 'In Review':
          return 'warning';
        default:
          return 'secondary';
      }
    },
    getExternalID(referral) {
      if (referral.provider === 'OPENLOOP') {
        return referral.provider_patient_id;
      }
      return referral.consult_id;
    },
    formatDatetime(datetime) {
      try {
        return moment(datetime).tz('UTC').format('YYYY-MM-DD HH:mm:ss');
      } catch (e) {
        return datetime;
      }
    },
    showPagination() {
      return this.total > 0;
    },
    changePage(page) {
      this.currentPage = page;
      this.fetchReferrals();
    },
    getUserEmail(userId) {
      const user = this.users[userId];
      return user ? user.email : 'Unknown';
    },
  },
  mounted() {
    this.fetchReferrals();
    this.fecthIsRequired();
  },
};
</script>
