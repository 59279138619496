<template>
  <b-card class="mb-4 border-0 card_orders_list">
    <div class="holder_table">
      <b-table :fields="tableFields" :busy="loadingPage" :items="orders" outlined sticky-header="500px" striped>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(created_at)="data">{{ data.item.created_at }}</template>
        <template #cell(order_id)="data">
          <b-link :to="`/orders/${data.item.order_id}`">{{ data.item.order_id }}</b-link>
        </template>
        <template #cell(type)="data">{{ translations.type[data.item.type] || data.item.type }}</template>
        <template #cell(component_type)="data">
          {{ translations.component_type[data.item.component_type] || data.item.component_type }}
        </template>
        <template #cell(sku)="data">{{ data.item.sku }}</template>
        <template #cell(client_name)="data">{{ data.item.client_name }}</template>
        <template #cell(method)="data">{{ data.item.method }}</template>
        <template #cell(ship_via)="data">{{ data.item.ship_via }}</template>
        <template #cell(status)="data">
          <div class="div_clex_name orders-colors">
            <span :class="[data.item.status, 'clex_name']">
              {{ translations.status[data.item.status] || data.item.status }}
            </span>
          </div>
        </template>
        <template #cell(actions)="data">
          <div class="btn-group dropleft actions-holder">
            <button variant="light" type="button" class="btn btn-light" data-toggle="dropdown" aria-expanded="false">
              <feather type="more-horizontal"></feather>
            </button>
            <div class="dropdown-menu">
              <orderAllowedActions
                :order="data.item">
              </orderAllowedActions>
            </div>
          </div>
        </template>
      </b-table>
    </div>
    <div class="row pt-2">
      <div class="col-12 d-flex justify-content-center">
        <b-pagination :disabled="loadingPage" @change="changePage" v-model="currentPage" :total-rows="total"
          :per-page="itemsPerPage" size="md">
        </b-pagination>
      </div>
    </div>
  </b-card>
</template>
<script>
import axios from 'axios';
import orderHandler from '@/mixins/orderHandler';
import translations from '@/translations';
import orderAllowedActions from '@/components/OrderManagement/Orders/OrderAllowedActions.vue';

export default {
  name: 'OrdersList',
  components: {
    orderAllowedActions,
  },
  props: {
    programUUID: {
      type: String,
      default: null,
    },
  },
  mixins: [ orderHandler ],
  data() {
    return {
      orders: [],
      loadingPage: false,
      itemsPerPage: 10,
      currentPage: 1,
      total: 0,
      translations: translations.orders,
      tableFields: [
        { key: 'created_at', label: 'Created At' },
        { key: 'order_id', label: 'Order ID' },
        { key: 'type', label: 'Type' },
        { key: 'component_type', label: 'Component Type' },
        { key: 'product_type', label: 'Product Type' },
        { key: 'sku', label: 'SKU' },
        { key: 'client_name', label: 'Client Name' },
        { key: 'method', label: 'Method' },
        { key: 'ship_via', label: 'Ship Via' },
        { key: 'status', label: 'Status' },
        { key: 'actions', label: 'Actions' },
      ],
    };
  },
  methods: {
    changePage(page) {
      this.loadOrders(page);
    },
    getStatusName(allowedStatus) {
      return allowedStatus.split('_to_')[1];
    },
    loadOrders(page) {
      // doesn't load orders if already loaded
      if (!page && this.orders.length > 0) {
        return;
      }

      this.currentPage = page;
      this.loadingPage = true;

      axios.get(`/v1/member-search/programs/${this.programUUID}/orders`, {
        params: {
          offset: (this.currentPage - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
        },
      }).then(response => {
        this.orders = response.data.orders;
        this.total = response.data.total;
      }).catch(err => {
        this.$noty.error('Unable to load orders: ', err);
      }).finally(() => {
        this.loadingPage = false;
      });
    },
  },
};
</script>

<style lang="scss">
@import '../../../styles/MemberSearch.scss';
</style>
