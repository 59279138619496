<script>
import axios from 'axios';

export default {
  name: 'InsuranceInfoDetails',
  props: {
    data: {
      type: Object,
      default: () => ({
        account: {},
      }),
    },
  },
  data() {
    return {
      patient: { ...this.data.account },
      payers: [],
      insuranceInfo: {
        clientID: 0,
        payerID: '',
        insuranceNumber: '',
      },
    };
  },
  computed: {
    isValid() {
      return this.patient.client_id > 0
        && this.insuranceInfo.payerID.trim().length > 0
        && this.insuranceInfo.insuranceNumber.trim().length > 0;
    },
    payer() {
      return this.payers.find(p => p.key === this.insuranceInfo.payerID);
    },
  },
  mounted() {
    this.getClientPayers();
    this.getInsuranceInfo();
  },
  methods: {
    onSave() {
      if (!this.isValid) {
        this.$noty.error('Insurance info data is missing');
        this.reset();
        return;
      }
      this.patchInsuranceInfo();
    },
    onCancel() {
      this.getInsuranceInfo();
    },
    reset() {
      this.insuranceInfo = { clientID: 0, payerID: '', insuranceNumber: '' };
    },
    getClientPayers() {
      axios.get(`v1/clients/${this.patient.client_id}/eligibility/settings`)
        .then(res => {
          const { payers } = res.data;
          this.payers = payers.data;
        }).catch(e => {
          let msg = 'Failed to get client payers.';
          if (e.response.data.error_description) {
            msg += ` (${JSON.stringify(e.response.data.error_description)})`;
          }
          this.$noty.error(msg);
        });
    },
    getInsuranceInfo() {
      axios.get(`v1/insurance-information/${this.data.account.uuid}`)
        .then(({ data }) => {
          this.insuranceInfo.clientID = +data?.client_id;
          this.insuranceInfo.payerID = data?.member?.payer_id;
          this.insuranceInfo.insuranceNumber = data?.member?.insurance_number;
        })
        .catch(e => {
          let msg = 'Failed to get insurance info data for this account.';
          if (e.response.data.error_description) {
            msg += ` (${JSON.stringify(e.response.data.error_description)})`;
          }
          this.$noty.error(msg);
        });
    },
    async patchInsuranceInfo() {
      try {
        await axios.patch(`v1/insurance-information/${this.data.account.uuid}`, {
          account_uuid: this.data.account.uuid,
          client_id: this.insuranceInfo.clientID,
          payer_id: this.insuranceInfo.payerID,
          insurance_number: this.insuranceInfo.insuranceNumber,
        });
        this.$noty.success('Insurance info data updated with success');
      } catch (e) {
        this.getInsuranceInfo();
        let msg = 'Failed to update insurance info data.';
        if (e.response.data.message) {
          msg += ` (${JSON.stringify(e.response.data.message)})`;
        }
        this.$noty.error(msg);
      }
    },
  },
};
</script>
<template>
  <b-row>
    <b-col cols="6">
      <ul class="list-unstyled">
        <li><b>Payer name</b>: {{ payer ? payer.name : ''  }}</li>
        <li><b>Insurance ID</b>: {{ insuranceInfo.insuranceNumber }}</li>
      </ul>
    </b-col>
    <b-col cols="6" class="text-right">
      <ul class="list-unstyled">
        <li>
          <b-badge role="button" variant="info" v-b-modal.modal-edit-insurance-info>Edit insurance information</b-badge>
        </li>
      </ul>
    </b-col>
    <b-modal id="modal-edit-insurance-info" title="Edit insurance information" @ok="onSave" @cancel="onCancel">
      <b-form-group label="Payer:">
        <b-form-select v-model="insuranceInfo.payerID" required>
          <b-form-select-option
            v-for="p in payers"
            :key="p.system_payer_id"
            :value="p.key">
            {{ p.name }} (ID: {{p.system_payer_id}})
          </b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group label="Insurance ID:">
        <b-form-input v-model="insuranceInfo.insuranceNumber" required />
      </b-form-group>
    </b-modal>
  </b-row>
</template>
